:root {
  --main-font-family: 'Nunito', sans-serif; /*  */
  --main-color-light: #FFFFFF; /* F9F8F3*/
  --main-color-tan-lightest: #F8F8F6;
  --main-color-tan-light: #F5F5F1;
  --main-color-tan-medium-light: #EDEBDF;
  --main-color-tan-medium: #D5D0BA;
  --main-color-tan: #BEBBAA;
  --main-color-tan-white: #FCFCFA;
  --main-color-tan-dark: #8C8478;
  --main-color-blue-dark: #17354C;
  --main-color-blue: #205CA4;
  --main-color-blue-medium: #4990DF;
  --main-color-blue-medium-light: #5DA5E6;
  --main-color-blue-light: #ECF2F8;
  --main-color-blue-white: #F6F9FC;
  --main-border-radius: 20px;
  --main-box-padding: 75px;
  --main-box-padding-mobile: 40px;
}

/* Reset some default margin and padding */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

div {
  font-family: var(--main-font-family);
}

a {
  text-decoration: none;
  color: var(--main-color-blue);
}
.link-text {
  color: var(--main-color-blue);
  cursor: pointer;
}

h2 {
  font-size: 1.4rem;
}

.icon {
  stroke: var(--main-color-blue);
  fill: var(--main-color-blue);
}

/* General Styles */
.container {
  max-width: 1200px;
  min-width: 1200px;
  padding: 0 0px; /* Add padding to prevent content from touching screen edges */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0 auto; /* Center the content */
  padding: 0; /* Remove padding to fit the content properly */
  background-color: white; /* Add a background color */
  border-radius: 0px; /* No rounded corners */
  height: 100vh;
  min-height: 800px;
}
.container-full {
  height: 100%;
}
.container-contact {
  min-height: 100vh;
  height: auto;
}
.container-velovino {
  min-height: 950px;
}

/* Adjust size for smaller screens */
@media (max-width: 900px) {
  .container {
    min-width: auto; /* Remove min-width to allow resizing on smaller screens */
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.3); /* remove shadow */  
    height: 100%;
  }

  h1 {
    font-size: 1.4rem;
  }

  h2 {
    font-size: 1.2rem; 
  }

  p {
    font-size: 0.9rem; 
  }
}

/* Page header */ 

.page-header {
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--main-color-light);
  font-size: 1.0rem;
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: space-between; /* Distribute space between items */
  color: white; /* Text color */
}

@media (max-width: 900px) {
  .page-header {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: var(--main-box-padding-mobile);
    flex-direction: column; 
    align-items: flex-start;
  }  
}

.horizontal-list {
  list-style-type: none; /* Remove bullet points */
  margin: 0;
  padding: 0;
  display: flex; /* Use flexbox for better control */
  margin-right: 15px;
}

.horizontal-list li {
  position: relative;
  display: inline-block; /* Make list items inline-block */
  margin-left: 20px; /* Add space between items */
  margin-right: 0px; /* Add space between items */
}

.horizontal-list li a {
  text-decoration: none; /* Remove underline from links */
  color: var(--main-color-blue-dark);
}
.horizontal-list li a:hover {
  text-decoration: none; 
  color: var(--main-color-blue-medium-light);
}
.horizontal-list li a.active, .horizontal-list li a:active {
  font-weight: bold; /* Make the active link bold */
  color: var(--main-color-blue-dark);
}

.dropdown-menu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--main-color-blue-light);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  padding: 10px 0;
  z-index: 1000;
  border-radius: 5px;
  width: 150px;
}
.dropdown-menu li {
  display: block;
  margin: 0;
  padding: 0;
}
.dropdown-menu li a {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: var(--main-color-blue-dark);
}
.dropdown-menu li a:hover {
  background-color: var(--main-color-blue-white);
  color: var(--main-color-blue-medium-light);
  text-decoration: none;
}
.dropdown-menu li:last-child {
  margin-right: 0px; 
}

.img-header-logo {
  background-color: var(--main-color-light);
  padding: 10px;
  height: 45px;
}

@media (max-width: 900px) {
  .horizontal-list {
    margin-top: 10px; 
    text-align: left;
  }
  .horizontal-list li {
    margin-left: 0px;
    margin-right: 20px;
  }

  .dropdown-menu {
    width: 125px;
  }
  .dropdown-menu li {
    font-size: 0.8rem;
    margin-right: 0px;
  }

  .img-header-logo {
    margin-bottom: 10px; 
    padding-left: 0px;
  }
}

.footer {
  background-color: var(--main-color-blue-light); 
  text-align: center;
  padding: 0px; 
  margin-top: auto; 
  color: var(--main-color-blue-dark);
  font-size: 0.9rem;
}

/* Make footer mobile-friendly */
@media (max-width: 900px) {
  .footer p {
    margin: 0px;
    padding: 5px 0px;
    font-size: 0.7rem;
  }
}


/* hero image */

.hero-container {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 1200px; 
  margin: 0 auto; 
  height: auto; 
}
.hero-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1;
}
.hero-image {
  width: 100%; 
  height: auto; 
  display: block; 
}
.hero-text {
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); 
  color: var(--main-color-blue-white); 
  text-align: center; 
  margin: auto;
  width: 100%;
  pointer-events: none; 
  z-index: 2;
}
.hero-text h1 {
  font-size: 3em;
  margin: 0;
  font-weight: normal;
}

@media (max-width: 900px) {
  .hero-text h1 {
    font-size: 1.3rem; 
  }
}

/* content */
.h1 {
  font-size: 2em;
  height: 100%;
}

.p {
  font-size: 1em;
}

.box-container {
  display: flex;
  justify-content: space-between; 
  gap:60px; 
  padding: 0 var(--main-box-padding); 
  margin: 40px 0; 
}
.box {
  flex: 1; /* Allow boxes to grow and shrink equally */
  padding: 20px;
  background-color: white; 
  border-radius: var(--main-border-radius);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  text-align: center; /* Center-align the content */
}
.box h2 {
  margin-top: 0; /* Remove top margin */
}
.box p {
  margin-bottom: 0; /* Remove bottom margin */
}
.box-icon {
  font-size: 2em; 
  margin-bottom: 20px; 
  margin-top: 5px;
  color: var(--main-color-blue); 
}

/* Adjust box-container layout for mobile */
@media (max-width: 900px) {
  .box-container {
    flex-direction: column; 
    padding: 0 var(--main-box-padding-mobile); 
    gap: 40px; 
    margin: 20px 0; 
  }

  .box {
    padding: 15px; 
    text-align: center;
    
  }
  .box-icon {
    margin-bottom: 5px;
    margin-top: 2px;
  }
}

.content {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.content-header {
  flex: 0 0 auto; /* The header does not grow or shrink, taking up only as much space as needed */
  text-align: center;
  background-color: var(--main-color-tan-light);
  color: var(--main-color-blue-dark);
  height: 10px;
  margin-top: 10px 0;
}
.content-header-text {
  flex: 0 0 auto; /* The header does not grow or shrink, taking up only as much space as needed */
  text-align: left;
  background-color: white;
  color: var(--main-color-blue-dark);
  font-size: 3em;
  padding: 0 var(--main-box-padding);
  font-weight: bold;
  margin-top: 40px;
}
@media (max-width: 900px) {
  .content-header-text {
    padding: 0 var(--main-box-padding-mobile);
    font-size: 2em;
  }
}


/* maps */
.journey-map {
  width: auto;
}

.leaflet-container {
  height: 600px;
  border-radius: var(--main-border-radius)
}

@media (max-width: 900px) {
  .leaflet-container {
    height: 300px;
  }    

  .journey-map {
    margin-left: 5px;
    margin-right: 5px;
  }  
}



.leaflet-popup-content {
  text-align: center;
}

.leaflet-popup-content img {
  width: 48px;
  height: 48px;
  display: block;
  margin: 0 auto; /* Centers the image */
  margin-bottom: -10px; /* Adjust this value to decrease or increase the gap */
}

.div-spacer-white {
  margin: 20px 0;
}

.div-spacer-small {
  height: 15px;
}
.div-spacer-medium {
  height: 40px;
}
.div-spacer-large {
  height: 50px;
}
.div-spacer-activities {
  padding-bottom: 20px;
}

/* stats */
.journey-stats {
  margin-top: 10px;
}

.stats-image-wrapper {
  position: relative;  /* Ensures the tooltip is positioned relative to this container */
}

.journey-stats-tooltip {
  position: absolute;
  top: -30%;  /* Adjust this value to place the tooltip vertically */
  left: 50%;  /* Position it to the right of the div */
  transform: translateX(-50%);
  margin-left: 0px;  /* Add some space between the div and the tooltip */
  background-color: var(--main-color-blue-medium);
  color: var(--main-color-blue-white);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.1rem;
  white-space: nowrap;
  z-index: 1000;
  opacity: 1;
}

.column-centred {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; /* Adds space between each stat item */
  width: 100%;
}

@media (max-width: 900px) {
  .journey-stats-tooltip {
    width: 115px;
    text-wrap: wrap;
    padding: 5px 10px;
    font-size: 0.85rem;
  }

  .column-centred {
    padding: 0px; /* Adds space between each stat item */
  }  

  .journey-stats {
    margin-bottom: 10px;
  }

  .div-spacer-activities {
    padding: 0px;
    height: 0px;
  }
}

.row-centred {
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
}

@media (max-width: 900px) {
  .row-centred {
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 5px;  
  }
}

.svg-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.svg-icon-medium {
  padding-top: 10px;
  margin-bottom: 5px;
}
.svg-icon-small {
  margin-top: 6px;
  margin-bottom: 0px;
}

@media (max-width: 900px) {
  .svg-icon-medium {
    margin-top: 10px;
    margin-bottom: 5px;
    height: 30px;
  }
  .svg-icon-small {
    margin-top: 6px;
    margin-bottom: 0px;
    height: 35px;
  }  
}

.stat-icon {
  stroke: var(--main-color-blue-white);
  fill: var(--main-color-blue-white);
}

.stats-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  align-items: center;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
}
.stats-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  align-items: center;
  width: 100%;
}

@media (max-width: 900px) {
  .stats-row {
    flex-direction: column;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 5px;
  }    

  .stats-group {
    padding: 5px 0px;
  }
}

.stats-image-medium {
  position: relative;
  width: 135px; 
  height: 135px; 
  background-color: var(--main-color-blue); 
  border-radius: 50%; /* This makes the div a circle */
}
.stats-image-small {
  position: relative;
  width: 115px;
  height: 115px; 
  background-color: var(--main-color-blue); 
  border-radius: 50%; /* This makes the div a circle */
}

.stat-text-medium {
  color: var(--main-color-blue-white);
  font-size: 1.1rem;
  padding-top: 0px;
}
.stat-text-medium-value {
  font-size: 1.27rem; 
  width: 135px;
  font-weight: bold;
  padding-top: 0px;
}

.stat-text-small {
  color: var(--main-color-blue-white);
  font-size: 0.85rem;
  font-weight: normal;
  padding-top: 0px;
}
.stat-text-small-value {
  font-size: 1.15rem; 
  width: 115px;
  font-weight: bold;
  padding-top: 1px;
}

@media (max-width: 900px) {
  .stats-image-medium {
    position: relative;
    width: 95px; 
    height: 95px; 
    margin-bottom: 15px;
  }  

  .stats-image-small {
    position: relative;
    width: 90px;
    height: 90px; 
    margin-bottom: 25px;
  }
  
  .stat-text-medium {
    font-size: 0.8rem;
    padding-top: 0px;
  }
  .stat-text-medium-value {
    font-size: 0.97rem; /* Adjust text size as needed */
    width: 95px;
    padding-top: 0px;
  }
  
  .stat-text-small {
    color: var(--main-color-blue-white);
    font-size: 0.7rem;
    font-weight: normal;
    padding-top: 1px;
  }
  .stat-text-small-value {
    font-size: 0.95rem; /* Adjust text size as needed */
    width: 90px;
    font-weight: bold;
    padding-top: 2px;
  }

}

/* Stats charts */
.chart-display h2 {
  padding-top: 20px;
  margin: 10px 0;
}

.stats-chart {
  max-width: 1180px;
  min-width: 1180px;
  margin: 0 auto;
  padding-bottom: 20px;
}

/* Adjust for smaller screens */
@media (max-width: 900px) {
  .stats-chart {
    min-width: auto;
    margin: 0 5px;
  }
  
}


/* Activity Selection */
.activity-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 0px;  
  padding-left: var(--main-box-padding);
}

.activity-stats-details {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;  
  margin-bottom: 20px;  
}

/* Adjust for smaller screens */
@media (max-width: 900px) {
  .activity-details {
    flex-direction: column;
    margin: 0px;
    padding: 0px;
  }

  .activity-stats-details {
    margin-top: 30px;  
    margin-bottom: 20px;  
    padding: 0px 15px;
  }  
}

.activity-selection {
  max-width: 300px;
}

/* Adjust for smaller screens */
@media (max-width: 900px) {
  .activity-selection {
    max-width: 320px;
    width: 100%;
    padding: 0 var(--main-box-padding-mobile);
    padding-bottom: 20px;
  }
}

.activity-selection-title {
  background-color: var(--main-color-tan-light);
  padding: 5px;
  font-weight: bold;
  font-size: 0.9rem;  
  text-align: left;
  padding-left: 10px;
}


.activity-stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 10px;
  font-weight: bold;
  width: 100%;
}

/* Adjust for smaller screens */
@media (max-width: 900px) {
  .activity-stats-details {
    flex-direction: column;
  }   
  
  .activity-stats {
    margin-bottom: 0px;
    margin-left: 0px;
  }
}

.activity-title {
  padding: 0 10px;
}
.activity-name {
  font-weight: normal;
  font-size: 1.7rem;
  text-align: left;
}
.activity-date {
  font-weight: lighter;
  font-size: 1.2rem;
  text-align: left;
}

.activity-map {
  padding-left: var(--main-box-padding);
}

@media (max-width: 900px) {
  .activity-title {
    padding-left: var(--main-box-padding-mobile);
  }

  .activity-map {
    padding: 0 0;
    margin-left: 5px;
    margin-right: 5px;
  }  
}

.scrollable-list {
  max-width: 280px;
  min-width: 260px;
  max-height: 120px;
  overflow-y: auto;
  border: 1px solid var(--main-color-tan-light);
  padding: 10px;
  border-radius: 5px;
  user-select: none; /* Prevent text selection */
  outline: none; /* Remove default focus outline */

}

/* Hide checkboxes on larger screens */
.activity-checkbox {
  display: none;
}

@media (max-width: 900px) {
  .scrollable-list {
    max-height: 120px;
    margin-bottom: 10px;
    max-width: none;
  }

  .activity-checkbox {
    display: inline-block;
    margin-right: 10px; 
  }

  .activity-item {
    display: flex;
    align-items: center; 
  }
}

.activity-item {
  display: block;
  padding: 2px 5px;
  padding-bottom: 2px;
  margin-bottom: 2px;
  cursor: pointer;
  text-align: left;
  border-radius: 4px;
  background-color: var(--main-color-light);
  transition: background-color 0.3s ease;
  user-select: none; /* Prevent text selection */
  font-size: 0.8rem;  
}

.activity-item:hover {
  background-color: var(--main-color-tan-medium-light);
}

.activity-item.selected {
  background-color: var(--main-color-tan);
  color: var(--main-color-light);
}

.activity-group-dropdown {
  font-family: var(--main-font-family);
  border: 0px solid var(--main-color-tan); 
  width: 100%;
  padding: 3px 5px;
  padding-bottom: 4px;
  outline: none; /* Remove default focus outline */
  transition: background-color 0.3s ease, border-color 0.3s ease; 
  border-radius: 4px;
  background-color: var(--main-color-tan);
  color: var(--main-color-light);
  font-size: 0.9rem;
  font-weight: bold;
}
.activity-group-dropdown:hover {
  background-color: var(--main-color-tan-medium);
  color: var(--main-color-light);  
}

.activity-group-dropdown option {
  background-color: var(--main-color-light); 
  color: black; 
  padding: 10px;
}
.activity-group-dropdown option:hover {
  background-color: var(--main-color-tan);
  color: var(--main-color-light); 
}

/* Custom dropdown container */
.activity-group {
  padding: 10px 10px;
  border: 1px solid var(--main-color-tan-light);
}

.custom-dropdown {
  position: relative;
  padding: 0px 3px;
  border: 0px solid var(--main-color-tan-light);
  border-radius: 5px;
  background-color: var(--main-color-tan);
  color: var(--main-color-light);
  font-size: 0.9rem;
  font-weight: normal;
  cursor: pointer;
  z-index: 10000;
}

/* Selected item display */
.dropdown-selected {
  padding: 3px;
  background-color: var(--main-color-tan);
  color: var(--main-color-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Dropdown options */
.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 0px solid var(--main-color-tan-light);
  background-color: var(--main-color-light);
  z-index: 10001;
  border-radius: 5px;
  overflow: hidden;
}

/* Each dropdown item */
.dropdown-item {
  padding: 5px;
  margin: 2px 5px;
  background-color: var(--main-color-light);
  color: var(--main-color-blue-dark);
  transition: background-color 0.3s ease;
  text-align: left;
  font-weight: normal;
  border-radius: 5px;
}

/* Hover effect for dropdown item */
.dropdown-item:hover {
  background-color: var(--main-color-tan-medium);
  color: var(--main-color-light);
  font-weight: normal;
}

/* Selected group styling */
.dropdown-item.selected {
  background-color: var(--main-color-tan);
  color: var(--main-color-light);
}

/* Custom arrow */
.arrow {
  font-size: 0.8rem;
  color: var(--main-color-light);
}

img.popup {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 50px;
  width: 50px;
}

/* Contact */
.contact-image {
  width: 100%; /* Make the image take up the full width of the container */
  height: auto; /* Maintain the image's aspect ratio */
  display: block; /* Remove any extra space below the image */
  margin-bottom: 30px;
  border-radius: var(--main-border-radius);
  
}

.contact-us {
  font-size: 1.2rem;
  text-align: left;
  padding: 0 var(--main-box-padding);
}

@media (max-width: 900px) {
  .contact-us {
    padding: 0 var(--main-box-padding-mobile);  
  }
}

/* About Me */
.about-me {
  background-color: white; /*var(--main-color-tan-light);*/
}

.about-me-text{
  text-align: left;
}

.about-me-image {
  border-radius: var(--main-border-radius);
  width: 300px;
}

.about-me-image-box {
  display: flex;
  justify-content: center; 
  align-items: flex-start;
}

/* Velo Vino */
.velo-vino-text {
  text-align: left;
  justify-content: left;
  margin-bottom: 0px;
}

.velo-vino-text-details {
  margin-right: 40px;
}
.velo-vino-text-line {
  display: flex;
  justify-content: left ;
  align-items: center;
}

.velo-vino-container {
  display: flex;
  justify-content: space-between; 
  gap:30px; 
}

.velo-vino-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
}
.velo-vino-box-text {
  padding: 5px;
}

.velo-vino-image {
  border-radius: var(--main-border-radius);
  max-width: 250px;
}

.dolomiti {
  padding: 0 20px;
}

.instagram-logo {
  margin: 20px 0;
  width: 30px;
}

/* Media query for mobile devices */
@media (max-width: 900px) {
  .velo-vino-text-details {
    margin-right: 0;
  }
  
  .velo-vino-container {
    flex-direction: column; /* Stack boxes vertically on smaller screens */
    align-items: center;
    gap: 10px;
  }

  .velo-vino-box p {
    margin-top: 0px;
  }

  .velo-vino-image {
    max-width: 300px;
  } 

  .dolomiti {
    margin: auto;
    padding: 0;
  }
}

/* loading message */
@keyframes pulse {
  0% {
    transform: scale(1) translateY(0);
  }
  50% {
    transform: scale(1.2) translateY(0);
  }
  100% {
    transform: scale(1) translateY(0);
  }
}

.loading {
  height: 100vh;
}
.loading-text {
  margin-top: 60px;
  font-size: 1.2rem;
  font-weight: bold;
  animation: pulse 1.5s infinite;
  color: var(--main-color-blue);
}

/* Services */
.services {
  display: flex;
  flex-direction: row;
  padding: 0;
  text-align: left;
}

.services-container {
  display: flex;
  justify-content: flex-start; 
  flex-direction: column;
  padding: 0 60px; 
  flex: 1;
}
.services-odd {
  background-color: var(--main-color-tan-lightest);
}
.services-even {
  background-color: white;
}

.services-content {
  display: flex;
  justify-content: flex-start; 
  flex-direction: column;
  gap: 0px; 
  margin: 0px 0; 
}
.services-content-detail {
  flex: 1; 
  padding: 0px;
  text-align: left;
  margin: auto;
  min-height: 220px;
}
.services-content-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0;
  color: var(--main-color-blue-dark);
  height: 60px;
  padding-bottom: 40px;
}
.services-content-title h2 {
  display: flex;
  align-items: flex-end; 
  font-size: 2.3rem;
  text-align: left;
  font-weight: normal;
  margin: 0;
}

.services-content-detail h3 {
  margin-top: 0;
  color: var(--main-color-blue-medium);
  height: 50px;
  margin-bottom: 10px;
}

.services-image {
  max-width: 180px;
  border-radius: var(--main-border-radius);
}

.svg-icon-services {
  display: flex;
  align-items: flex-end; 
  height: 100%; 
}
.svg-icon-services-padded {
  padding-right: 15px;
}
.icon-services {
  stroke: var(--main-color-blue-dark);
  fill: var(--main-color-blue-dark); 
}


@media (max-width: 900px) {
  .services {
    flex-direction: column;
  }

  .services-container {
    padding: 0 var(--main-box-padding-mobile); 
    margin: auto;
    width: auto;
  }

  .services-content {
    flex-direction: column; 
    padding: 0; 
    margin: 0; 
  }

  .services-content-title {
    padding-right: 40px;
    padding-bottom: 20px;
  }

  .services-content-detail {
    min-height: auto;
    padding-bottom: 20px;    
  }  

  .services-content-title h2 {
    font-size: 1.8rem;
  }
  .services-content-detail h3 {
    margin-top: 10px;
    height: auto;
    font-size: 1.1rem;
  }
  .services-content-detail {
    font-size: 0.9rem;

  }  
}

.emoji-image {
  height: 16px;
  vertical-align: middle;
  margin-bottom: 3px;
}

.welcome {
  display: flex;
  text-align: left;
  margin: 60px 0;
  font-size: 1rem;
}
.welcome-text-box {
  flex: 2;
}
.welcome-text {
  padding: 0 60px;
}
.welcome-text b {
  color: var(--main-color-blue);
}
.welcome-image {
  flex: 1;
  display: flex;
  justify-content: center;
}
.welcome-img {
  border-radius: var(--main-border-radius);
  width: 280px;
}


@media (max-width: 900px) {
  .welcome {
    flex-direction: column; 
    padding: 0 var(--main-box-padding-mobile); 
    gap: 40px; 
    margin: 30px 0; 
    align-items: center;
    font-size: .95rem;
  }
  .welcome-text {
    padding: 0 0;
  }  
}

/* back button and velo vino header */
.velovino-nav {
  cursor: pointer;
  margin-left: auto; 
}
.velovino-nav-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  margin-right: 15px;
}
.icon-velovino-nav {
  stroke: var(--main-color-blue-dark);
  fill: var(--main-color-blue-dark);
}
.velovino-nav:hover .velovino-nav-tooltip-container:hover .icon-velovino-nav {
  stroke: var(--main-color-blue-medium-light);
  fill: var(--main-color-blue-medium-light);
}
.velovino-nav-tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
  
}
.velovino-nav-tooltip-text {
  visibility: hidden; /* Initially hidden */
  background-color: var(--main-color-blue-medium-light);
  color: var(--main-color-blue-white);
  text-align: center;
  padding: 2px 10px;
  border-radius: 5px;
  position: absolute;
  bottom: 80%; /* Position above the SVG */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0; /* For fade-in effect */
  transition: opacity 0.3s; /* Smooth fade-in */
  white-space: nowrap; /* Prevent line break */
  z-index: 1;
  font-size: 0.8rem;

  display: flex;
  justify-content: center; 
  align-items: center;
  height: 20px;
}

.velovino-nav-tooltip-container:hover .velovino-nav-tooltip-text {
  visibility: visible;
  opacity: 1; /* Make tooltip visible on hover */
}

.velo-vino-header {
  display: flex;
  justify-content: space-between; 
  padding-right: 0px;
}


@media (max-width: 900px) {
  .velo-vino-header {
    display: flex;
    flex-direction: column; 
    margin-right: 10px;
  }

  .velovino-nav {
    padding-top: 20px;
    margin-left: 0px; 
  }

  .velovino-nav-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start; 
    margin-right: 15px;
  }
  
}

.distance-subheading {
  color: var(--main-color-blue);
}
.distance-subheading h4 {
  color: var(--main-color-blue);
  margin: 5px 0;
}

/* Contact form */
/* Container to organise everything */
.contact-form-container {
  display: block;
}
.contact-form-message {
  width:100%;
  padding-bottom: 30px;
  margin-top: 40px;
}
.contact-form-thank-you {
  padding-bottom: 30px;
  margin-top: 40px;
}

/* Expand the contact page to full height after submission */
.contact-full-height {
  min-height: 100px;
}

/* Box to center the form */
.contact-form-box {
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center;     /* Centers vertically */
  padding-bottom: 30px;           
}


/* Form styling */
.contact-form {
  width: 100%;
  max-width: 600px;        /* Sets a maximum width for the form */
  background-color: var(--main-color-tan-lightest); /* Optional: form background color */
  padding: 20px;
  border-radius: var(--main-border-radius);
  /*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

/* Form group to organize labels and inputs */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

/* Label styling */
.form-group label {
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 1rem;
}

/* Input and textarea styling */
.form-input,
.form-textarea {
  padding: 12px;
  font-size: 1rem;
  border: 1px solid var(--main-color-tan);
  border-radius: 4px;
  transition: border-color 0.3s;
}

.form-input:focus,
.form-textarea:focus {
  border-color: var(--main-color-blue-medium);
  outline: none;
}

/* Error message styling */
.error {
  color: #ff0000;
  font-size: 0.9rem;
  margin-top: 5px;
}

/* Submit button styling */
.submit-button {
  width: 100%;
  padding: 14px 20px;
  font-size: 1.2rem;
  background-color: var(--main-color-blue-medium);
  color: var(--main-color-light);            /* Button text color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color:var(--main-color-blue); /* Darkens the button on hover */
}
